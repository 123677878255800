//@ts-nocheck
import { createSelector } from 'reselect'
import type { User } from 'types/User'
import 'types/User'
import type { Cookie } from 'types/User/Cookie'
import { defaultCurrencyRate, defaultCurrencyCode, defaultLocale } from 'lib/i18'
import { getAvailableCurrencies } from 'selectors/shared/locale'
import { getUser } from './helpers'
export const getCookies = createSelector([getUser], (user: User): Cookie => user.cookie || {})
export const getSaatchiVC = createSelector([getCookies], ({ saatchivc }): string => saatchivc || '')
export const getAlgoliaUserToken = createSelector(
  [getCookies],
  ({ saatchivc }): string => saatchivc || 'Anonymous'
)
export const getUserPreferences = createSelector(
  [getCookies],
  ({ userPreferences }): Record<string, any> => userPreferences || {}
)
export const getUserPreferencesCountry = createSelector(
  [getUserPreferences],
  ({ country }): string => country || 'US'
)
export const getUserPreferencesCurrency = createSelector(
  [getUserPreferences],
  ({ currency }): string => currency || 'USD'
)
export const getUserPreferencesDestinationZone = createSelector(
  [getUserPreferences],
  ({ destinationZone }): string => destinationZone || 'US'
)
export const getUserPreferencesMeasurementUnit = createSelector(
  [getUserPreferences],
  ({ measurementUnit }): 'in' | 'cm' => measurementUnit || 'in'
)
export const getSelectedCurrency = createSelector(
  [getAvailableCurrencies, getUserPreferencesCurrency],
  (availableCurrencies, currencyCode): Record<string, any> => {
    return availableCurrencies[currencyCode] || defaultCurrencyRate
  }
)
export const getSelectedCurrencyCode = createSelector(
  [getSelectedCurrency],
  (selectedLocale): string => selectedLocale.currencyCode || defaultCurrencyCode
)
export const getSelectedCurrencyLocale = createSelector(
  [getSelectedCurrency],
  (selectedLocale): string => selectedLocale.locale || defaultLocale
)
export const getHasDefaultCurrency = createSelector(
  [getSelectedCurrencyCode],
  (currencyCode): boolean => currencyCode === defaultCurrencyCode
)