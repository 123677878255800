/*
 * This hook is to clear local storage based on future timestamp
 */
import { isAvailable, get as getStorage } from 'lib/localStorage'

const LOCAL_STORAGE_KEYS = [`algolia_query_id`] // Manually list local storage objects you want to delete

const useClearLocalStorage = () => {
  let storageValue
  if (isAvailable) {
    const now = new Date().getTime()
    LOCAL_STORAGE_KEYS.map((key) => {
      try {
        storageValue = getStorage(key)
      } catch (err) {
        console.error('Error accessing localStorage', { err })
        return
      }

      const expirationTimestamp = storageValue?.futureExpirationUnixTimestamp

      if (expirationTimestamp && expirationTimestamp < now) {
        localStorage.removeItem(key)
      }
    })
  }
}

export default useClearLocalStorage
