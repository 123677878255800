import { createSelector } from 'reselect'
import type { Page } from 'types/Page'
import 'types/Page'
type State = {
  page: Page
}
export const getPage = (state: State) => state?.page || {}
export const getPageData = createSelector([getPage], (page): Record<string, any> => page?.data || {})
export const getPageIsReady = createSelector([getPage], (page): boolean => page?.isReady || false)
export const getPageName = createSelector([getPage], (page): string => page?.name || '')
export const getPageParams = createSelector(
  [getPage],
  (page): Record<string, any> => page?.params || {}
)
export const getPagePath = createSelector([getPage], (page): string => page?.path || '')
export const getSponsoredItems = createSelector(
  [getPageData],
  (pageData): Array<Record<string, any>> => pageData.sponsoredItems || []
)
export const getPageParamsQuery = createSelector(
  [getPageParams],
  (pageParams): Record<string, any> => pageParams.query || ''
)
export const getIsIOSWebview = createSelector(
  [getPageData],
  (pageData): boolean => pageData.iOSWebview || false
)