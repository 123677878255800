// TODO - GEO PRICES - Look at Possibly moving to LIB folder
// Further discussion
import ISO2_GEO_ZONES from 'server/constants/iso2_geo_zones'
import { getCookie, decodeCookie } from 'lib/cookies'
const { SITE_URL } = process.env
export const removeRepeatedForwardSlash = (value: string): string => {
  // Remove repeating `//en-ca/path/////name` => /en-ca/path
  return value.replace(/[/]{2,}/g, '/')
}
export const getGeoZoneDestinationCode = (country: string): string => {
  return ISO2_GEO_ZONES[country] || 'US'
}

const getFullURL = (url: string): URL => {
  const result = new URL(url || '', SITE_URL || 'https://www.saatchiart.com')
  return result
}

const shouldIgnoreURL = (url: URL): boolean => {
  const { href, hostname, protocol } = url
  // Skip Rules
  const envResult = new URL('', SITE_URL || 'https://www.saatchiart.com')
  const { hostname: envHostname } = envResult
  const isEaselAPi = href.search(/easel_api/) > -1
  const isDifferentDomain = hostname !== envHostname
  const isMailProtocol = protocol === 'mailto:'

  if (isDifferentDomain || isMailProtocol || isEaselAPi) {
    return true
  }

  return false
}

export const getStoreLocaleByCookie = (): string => {
  const userPreferencesCookie =
    getCookie({
      name: 'saatchi_user_preferences',
    }) || ''
  const userPreferencesValue = decodeCookie(userPreferencesCookie) // { country: 'CA', ... }

  const userPreferences = JSON.parse(userPreferencesValue)
  const { storeLocale } = userPreferences
  return storeLocale
}
export const addStoreLocaleByCookie = (url: string): string => {
  const result = getFullURL(url)
  const { hash, pathname, search } = result

  if (shouldIgnoreURL(result)) {
    return url
  }

  const storeLocale = getStoreLocaleByCookie()
  const updatedPath = createNewURLPath(pathname, storeLocale)
  return `${updatedPath}${hash || ''}${search || ''}`
}
export const getBrowserStoreLocale = (): string => {
  const path = window.location.pathname || ''
  // /en-ca/checkout/shipping => /checkout/shipping
  const [tempStoreLocale] = path.match(/\/en-[A-Za-z]{2}/) || ['']

  if (!tempStoreLocale) {
    return 'en-us'
  }

  return tempStoreLocale.replace('/', '')
}
export const getCurrentBrowserPath = (): string => {
  const path = window.location.pathname || ''
  const query = window.location.search || ''
  const hash = window.location.hash || ''
  // /checkout/shipping#table?price=usd
  return path + hash + query
}
export const getCleanBrowserPath = (): string => {
  const path = window.location.pathname || ''
  const query = window.location.search || ''
  const hash = window.location.hash || ''
  // /en-ca/checkout/shipping => /checkout/shipping
  return path.replace(/\/en-[A-Za-z]{2}/, '') + hash + query
}
export const createNewBrowserPath = (storeLocale: string = ''): string => {
  const path = getCleanBrowserPath()

  if (storeLocale === 'en-us') {
    return removeRepeatedForwardSlash(path)
  }

  return removeRepeatedForwardSlash(`/${storeLocale}${path}`)
}
export const getCleanURLPath = (url: string): string => {
  // /en-ca/checkout/shipping => /checkout/shipping
  return url.replace(/\/en-[A-Za-z]{2}/, '')
}
export const createNewURLPath = (url: string, storeLocale: string = ''): string => {
  const path = getCleanURLPath(url)

  if (storeLocale === 'en-us') {
    return removeRepeatedForwardSlash(path)
  }

  return removeRepeatedForwardSlash(`/${storeLocale}${path}`)
}
export const createNewURL = (url: string, storeLocale: string = ''): string => {
  const result = getFullURL(url)
  const { hash, origin, pathname, search } = result

  if (shouldIgnoreURL(result)) {
    return url
  }

  const updatedPath = createNewURLPath(pathname, storeLocale)
  const newURL = `${origin}${updatedPath}${hash || ''}${search || ''}`
  return newURL
}
// We only want to update URL on the same domain ( our website )
export const URLParse = (url: string, storeLocale: string): Record<string, any> => {
  const result = getFullURL(url)
  const { hash, href, origin, pathname, search } = result

  if (shouldIgnoreURL(result)) {
    return {
      isModified: false,
      href,
      pathname,
    }
  }

  const updatedPath = createNewURLPath(pathname, storeLocale)
  return {
    isModified: true,
    href: `${origin}${updatedPath}${hash || ''}${search || ''}`,
    pathname: updatedPath,
  }
}
