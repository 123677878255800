//@ts-nocheck
import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './shared/helpers'
import { getUserPreferencesCountry, getUserPreferencesDestinationZone } from './user/cookie'
import { getAvailableCountries, getLocaleOrder } from './shared/locale'
import { getFeatureFlagsGeoPrices } from './shared/featureFlags'
// We are pulling data from different attributes in REDUX and assembly the facts
export type GeoLocation = {
  hasFeatureFlagsGeoPricesForAustralia: boolean
  isFeatureFlagsGeoPrices: boolean
  storeLocale: string
  userPreferencesCountry: string
  userPreferencesCountryFullName: string
  userPreferencesDestinationZone: string
}
export type GeoLocationMetaData = {
  isFeatureFlagsGeoPrices: boolean
  localeOrder: Array<string>
  storeLocale: string
  urlLocation: string
}
export const getStoreLocale = createSelector(
  [getShared],
  (shared: Shared): Record<string, any> => shared.storeLocale || ''
)
export const getStoreFullName = createSelector(
  [getUserPreferencesCountry, getAvailableCountries],
  (userPreferencesCountry, availableCountries): string =>
    availableCountries[userPreferencesCountry] || ''
)
export const getFeatureFlagsGeoPricesForAustralia = createSelector(
  [getUserPreferencesCountry, getFeatureFlagsGeoPrices, getStoreLocale],
  (
    userPreferencesCountry,
    isFeatureFlagsGeoPrices,
    storeLocale
  ): string => // We do NOT want to set user preferences due to CACHE, so we need to check the STORELOCALE for Server Side
    (isFeatureFlagsGeoPrices && userPreferencesCountry === 'AU') ||
    (isFeatureFlagsGeoPrices && storeLocale === 'en-au')
)
export const getURLStoreLocalePrefix = createSelector(
  [getFeatureFlagsGeoPricesForAustralia, getStoreLocale],
  (hasFeatureFlagsGeoPricesForAustralia, storeLocale): string => {
    // TODO - GEO PRICES - remove later
    // We do NOT want to set user preferences due to CACHE, so we need to check the STORELOCALE for Server Side
    if (hasFeatureFlagsGeoPricesForAustralia && storeLocale === 'en-au') {
      return '/en-au'
    }

    return ''
  }
)
export const getGeoLocation = createSelector(
  [
    getStoreLocale,
    getUserPreferencesCountry,
    getUserPreferencesDestinationZone,
    getFeatureFlagsGeoPrices,
    getStoreFullName,
    getFeatureFlagsGeoPricesForAustralia,
    getURLStoreLocalePrefix,
  ],
  (
    storeLocale,
    userPreferencesCountry,
    userPreferencesDestinationZone,
    isFeatureFlagsGeoPrices,
    storeFullName,
    hasFeatureFlagsGeoPricesForAustralia,
    urlStoreLocalePrefix
  ): GeoLocation => {
    return {
      hasFeatureFlagsGeoPricesForAustralia,
      isFeatureFlagsGeoPrices,
      storeLocale,
      urlStoreLocalePrefix,
      userPreferencesCountry,
      userPreferencesCountryFullName: storeFullName,
      userPreferencesDestinationZone,
    }
  }
)
export const getGeoLocationMetaData = createSelector(
  [getFeatureFlagsGeoPrices, getStoreLocale, getLocaleOrder],
  (isFeatureFlagsGeoPrices, storeLocale, localeOrder): GeoLocationMetaData => {
    return {
      isFeatureFlagsGeoPrices,
      localeOrder,
      storeLocale,
    }
  }
)