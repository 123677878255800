export const PrepareSchema = (schema: Record<string, any>): Record<string, any> | null => {
  if (schema && schema.url) {
    schema.url = encodeURI(schema.url)
  }

  // No Closing `</script>` (XSS Attacks)
  if (JSON.stringify(schema).search(/<\/script/gi) > -1) {
    // Bad Code (Don't Render)
    return null
  }

  return JSON.stringify(schema)
}
export const siteSchema = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'saatchiart.com',
  alternateName: 'Saatchi Art',
  url: 'https://www.saatchiart.com',
}
export const pageSchema = {
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  url: 'https://saatchiart.com',
  mainEntityOfPage: 'https://saatchiart.com',
  sameAs: [
    'https://www.youtube.com/Saatchiart',
    'https://www.facebook.com/saatchionline',
    'https://twitter.com/SaatchiArt',
    'https://www.instagram.com/saatchiart',
    'https://www.pinterest.com/saatchiart',
  ],
  keywords: ['original art', 'art', 'artworks', 'art for sale', 'gallery'],
  inLanguage: 'en-us',
  headline: " The World's Leading Online Art Gallery | Saatchi Art ",
  description: 'Discover and buy original artwork from emerging artists around the world.',
  isFamilyFriendly: 'True',
}
export const organizationSchema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://www.saatchiart.com',
  logo: 'https://d3t95n9c6zzriw.cloudfront.net/common/saatchi-art-logo-1x.png',
  brand: 'Saatchi Art',
  sameAs: [
    'https://www.youtube.com/c/Saatchiart',
    'https://www.facebook.com/saatchionline',
    'https://twitter.com/SaatchiArt',
    'https://www.instagram.com/saatchiart',
    'https://www.pinterest.com/saatchiart',
  ],
}