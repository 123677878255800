"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGoogleCookieUserId = exports.parseAbTestCookieValue = exports.decodeCookie = exports.deleteCookie = exports.setCookie = exports.getCookie = void 0;
const secondsPerDay = 60 * 60 * 24;
const defaultExpiration = 31;
// Get Cookie From Server or Browser
const getCookie = (params) => {
    const { req, name } = params;
    if (req && req.cookies) {
        return req.cookies[name];
    }
    else if (typeof window !== 'undefined') {
        const cookies = document.cookie.split(';');
        return (cookies.find((item) => item.search(`${name}=`) > -1) || '').split('=')[1];
    }
    return '';
};
exports.getCookie = getCookie;
// Create/Renew Cookies
const setCookie = (params) => {
    const { req, res, name, value, expiration } = params;
    const maxAge = (expiration || defaultExpiration) * secondsPerDay * 1000;
    const cookieValue = typeof value === 'function' ? value() : value || '';
    if (req && res) {
        res.cookie(name, cookieValue, {
            domain: `.saatchiart.com`,
            maxAge,
            sameSite: 'lax',
            secure: true,
        });
    }
    else if (typeof window !== 'undefined') {
        const cookie = `${name}=${cookieValue};domain=.saatchiart.com;maxAge=${maxAge};path=/;`;
        document.cookie = cookie;
    }
    return cookieValue;
};
exports.setCookie = setCookie;
// Create/Renew Cookies
const deleteCookie = (params) => {
    const { req, res, name } = params;
    if (req && res) {
        res.cookie(name, '', {
            expires: 0,
        });
    }
    else if (typeof window !== 'undefined') {
        const cookie = `${name}=;domain=.saatchiart.com;expires=0;path=/;`;
        document.cookie = cookie;
    }
};
exports.deleteCookie = deleteCookie;
// Decode Cookie - Only write use cases we need to handle
// unescape() is being deprecated and decodeURI/decodeURIComponent is recommended option
// it doesn't escape/parse all characters so you can add special cases
// 'search+query%20%28correct%29' => 'search query (correct)'
const decodeCookie = (value) => decodeURIComponent(value.replace(/\+/g, ' '));
exports.decodeCookie = decodeCookie;
// CookieValue => '01.03,02.04,06.38,'
// Return response => { '01': '03', ... }
const parseAbTestCookieValue = (cookieValue) => {
    if (!cookieValue) {
        return {};
    }
    const parseValue = {};
    cookieValue.split(',').forEach((test) => {
        const [key, value] = test.split('.');
        parseValue[key] = value;
    });
    return parseValue;
};
exports.parseAbTestCookieValue = parseAbTestCookieValue;
// Google Cookie => '_ga'
// https://stackoverflow.com/questions/16102436/what-are-the-values-in-ga-cookie
const getGoogleCookieUserId = () => {
    const googleUserCookie = (0, exports.getCookie)({
        name: '_ga',
    });
    const [version, domain, userId, timestamp] = (googleUserCookie || '').split('.');
    return `${userId || 0}.${timestamp || 0}`;
};
exports.getGoogleCookieUserId = getGoogleCookieUserId;
