"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
// Used for Locale Preferences both BE & FE
const defaultLocaleCountryCode = 'US';
// technically the EU isn't a country but we treat it that way for currency
// conversion purposes.
const europeanUnionIso2CountryCode = 'EU';
const userPreferencesCookieName = 'saatchi_user_preferences';
const localeCookieName = 'saatchi_preferred_locale';
const defaultLocale = 'en-US'; // en-US
const defaultCurrencyCode = 'USD';
const defaultCurrencyRate = {
    countryCode: 'US',
    currencyCode: defaultCurrencyCode,
    displayName: 'United States',
    exchangeRate: 1,
    locale: 'en-US',
};
const localeCookieExpiration = 30; // Days | 1 month
const getUserPreferredLocale = (req) => {
    if (req && req.cookies) {
        return req.cookies[localeCookieName] || defaultLocale;
    }
    return defaultLocale;
};
// Preferred Locale Cookie
const getCurrencyExchangeRateByLocale = (data, locale) => {
    if (data[locale]) {
        return data[locale];
    }
    return defaultCurrencyRate;
};
// Akamai Country Code
const getCurrencyExchangeRateByCountryCode = (data, countryCode) => {
    let localeCurrencyRate = defaultCurrencyRate;
    Object.values(data).forEach((value) => {
        if (value.countryCode === countryCode) {
            localeCurrencyRate = value;
        }
    });
    return localeCurrencyRate;
};
// map incoming akamai iso2 countries to a different value e.g. unify all
// countries using the euro
//
// This map is here because we only have one item for euros in the currency
// selector dropdown in the site UI. This allows us to map incoming akamai
// country headers for countries that are in the EU to the euros selection in
// that dropdown.
const europeanUnionCountries = {
    AT: europeanUnionIso2CountryCode,
    BE: europeanUnionIso2CountryCode,
    BG: europeanUnionIso2CountryCode,
    HR: europeanUnionIso2CountryCode,
    CY: europeanUnionIso2CountryCode,
    CZ: europeanUnionIso2CountryCode,
    DK: europeanUnionIso2CountryCode,
    EE: europeanUnionIso2CountryCode,
    FI: europeanUnionIso2CountryCode,
    FR: europeanUnionIso2CountryCode,
    GR: europeanUnionIso2CountryCode,
    DE: europeanUnionIso2CountryCode,
    HU: europeanUnionIso2CountryCode,
    IE: europeanUnionIso2CountryCode,
    IT: europeanUnionIso2CountryCode,
    LV: europeanUnionIso2CountryCode,
    LT: europeanUnionIso2CountryCode,
    LU: europeanUnionIso2CountryCode,
    MT: europeanUnionIso2CountryCode,
    NL: europeanUnionIso2CountryCode,
    PL: europeanUnionIso2CountryCode,
    PT: europeanUnionIso2CountryCode,
    RO: europeanUnionIso2CountryCode,
    SK: europeanUnionIso2CountryCode,
    SI: europeanUnionIso2CountryCode,
    ES: europeanUnionIso2CountryCode,
    SE: europeanUnionIso2CountryCode,
};
const iso2CountryOverrideMap = Object.assign({}, europeanUnionCountries);
module.exports = {
    defaultCurrencyCode,
    defaultCurrencyRate,
    defaultLocale,
    defaultLocaleCountryCode,
    getCurrencyExchangeRateByCountryCode,
    getCurrencyExchangeRateByLocale,
    getUserPreferredLocale,
    iso2CountryOverrideMap,
    localeCookieExpiration,
    localeCookieName,
    userPreferencesCookieName,
};
