//@ts-nocheck
import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './helpers'
type FeatureFlags = Record<string, boolean>
export const getFeatureFlags = createSelector(
  [getShared],
  (shared: Shared): FeatureFlags => shared.featureFlags || {}
)
export const getFeatureFlagsTest = createSelector(
  [getFeatureFlags],
  (featureFlags: FeatureFlags): boolean => featureFlags.Test || false
)
export const getFeatureFlagsGeoPrices = createSelector(
  [getFeatureFlags],
  (featureFlags: FeatureFlags): boolean => featureFlags.Geo_Prices_Australia || false
)