"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryObject = exports.getPathObject = exports.getSiteContext = exports.getWebsite = exports.getHref = exports.getProtocol = exports.getHostname = exports.getPath = exports.getQuery = exports.parse = void 0;
/*
> const myURL = new URL('/foo?q=1&s=hello', 'https://example.org/');
undefined
> myURL
URL {
  href: 'https://example.org/foo?q=1&s=hello',
  origin: 'https://example.org',
  protocol: 'https:',
  username: '',
  password: '',
  host: 'example.org',
  hostname: 'example.org',
  port: '',
  pathname: '/foo',
  search: '?q=1&s=hello',
  searchParams: URLSearchParams { 'q' => '1', 's' => 'hello' },
  hash: ''
}
*/
const { SITE_URL } = process.env;
const parse = (location) => {
    const result = new URL(location || '', SITE_URL || 'https://www.saatchiart.com');
    const { pathname, search } = result;
    /* We need to  strip out brackets `[]` from query array parameters -_-
    searchParams: URLSearchParams {
      'styles[]' => 'impressionism',
      'styles[]' => 'expressionism',
      'is_framed' => 'true',
      'price' => '0-25000',
      'page' => '0' },
    */
    // $FlowBug add a path attribute
    result.path = `${pathname}${search}`;
    // $FlowBug unable to infer result.query is an object
    result.query = search
        .replace(/\?/g, '')
        .split(/&/)
        .reduce((results, item) => {
        const [key, value] = item.split(/=/);
        if (key.search(/\[\]/) > -1) {
            const cleanKey = key.replace(/\[\]/g, '');
            results[cleanKey] = results[cleanKey] ? [...results[cleanKey], value] : [value];
            return results;
        }
        results[key] = value;
        return results;
    }, {});
    return result;
};
exports.parse = parse;
const getQuery = (req) => {
    const { searchParams: query } = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    return query;
};
exports.getQuery = getQuery;
const getPath = (req) => {
    const url = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    const { pathname } = url || {
        pathname: '',
    };
    return pathname.replace(/\/$/, ''); // remove trailing slash
};
exports.getPath = getPath;
const getHostname = (req) => {
    const url = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    const { hostname } = url || {};
    return hostname || '';
};
exports.getHostname = getHostname;
const getProtocol = (req) => {
    const { protocol } = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    return protocol;
};
exports.getProtocol = getProtocol;
const getHref = (req) => {
    const { href } = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    return href;
};
exports.getHref = getHref;
const getWebsite = (req) => {
    const { hostname } = req
        ? req.location
        : (0, exports.parse)(typeof window !== 'undefined' ? window.location.toString() : '');
    return `://${hostname}`;
};
exports.getWebsite = getWebsite;
const getSiteContext = (req) => {
    if (!req) {
        return 'saatchiart';
    }
    const hostname = (0, exports.getHostname)(req);
    const pathname = (0, exports.getPath)(req);
    if (hostname.search(/limited/) > -1) {
        return 'limited';
    }
    else if (pathname.search(/theotherartfair/) > -1) {
        return 'toaf';
    }
    return 'saatchiart';
};
exports.getSiteContext = getSiteContext;
// Pass in url, array of keys to map back to
// ('print/photography/abstract', ['type', 'category', 'style'])
// return { type: 'print', category: 'photography', style: 'abstract' }
const getPathObject = (href, urlKeys) => {
    const pathObject = {};
    const urlValues = ((0, exports.parse)(href).pathname || '')
        .replace(/\/en-[A-Za-z]{2}/, '') // Strip Out - Geo Locale
        .replace(/(^\/)/, '') // Strip Out - Initial `/`
        .split('/');
    urlKeys.map((key, index) => (pathObject[key] = urlValues[index]));
    return pathObject;
};
exports.getPathObject = getPathObject;
const getQueryObject = (href) => (0, exports.parse)(href).query || {};
exports.getQueryObject = getQueryObject;
exports.default = exports.parse;
