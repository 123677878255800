import React from 'react'
import { PrepareSchema } from './schemas'
type Props = {
  data: Record<string, any>
}

const LDJSON = ({ data }: Props): React.ReactElement<React.ComponentProps<any>, any> => (
  <script
    type='application/ld+json'
    dangerouslySetInnerHTML={{
      __html: PrepareSchema(data),
    }}
  />
)

export default LDJSON